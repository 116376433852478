

<template>
  <!--  <img alt="Vue logo" src="./assets/logo.png">
    -->
  <!--  <div class="app-shell"> -->
  <v-app style="background-color: var(--mid_grey);">
    <router-view></router-view>
  </v-app>
  <!--</div> -->
  <!-- <SplashScreen  /> -->
  <!--
   <SplashScreen v-if='featuresStore.ready === false' ref="Splash"/>
 -->
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
//import { usefeaturesStore } from "@/stores/featuresStore";

//import SplashScreen  from "./components/loaders/SplashScreen.vue";

//const featuresStore = useFeaturesStore();
//const modelStore = useModelStore();

//import { useConfigStore } from "@/stores/config";


export default {
  name: 'App',
  components: {
    // SplashScreen
    // HelloWorld
  },
  computed: {
    routes () {
      return [
        {
          to: { name: 'home' },
          name: 'Home',

        },
        {
          to: { name: 'cv' },
          name: 'cv'
        },
        {
          to: { name: 'filmography' },
          name: 'filmography'
        },
        {
          to: { name: 'devlog-floodplain' },
          name: 'devlog-floodplain'
        },
        {
          to: { name: 'devlog' },
          name: 'devlog'
        },
        {
          to: { name: 'folio' },
          name: 'folio'
        }
      ]
    }
  },
  setup(){
    console.log("%c" + "Zach Thrun's CV + Portfolio - rzthrun.com", "color: #0b78dd;font-weight:bold;");
    console.log("App--> setup-->");
   // const configStore = useConfigStore();

    //configStore.initializeURLState()
    return {
      //    modelStore
    }
  }
}
</script>

<style>
  /*@font-face {
    font-family: 'myriad-pro';
    src: url('https://use.typekit.net/hid5hqk.css')
  }

   */

 html{
   --white: #ffffff;
   --grey: #777777;
   --light_grey: #adadad;
   --dusting_grey: #f8f9f9;
    --black: #000000;
   --mid_grey: #555555;

 }


#app {
  font-family: "myriad-pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;

  /* font-family: Avenir, Helvetica, Arial, sans-serif;

   */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
