//import App from './App.vue'
//import createRouter from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
//import $ from "jquery";
//import {useAppStateStore} from "@/appStateStore";

//const appState = useAppStateStore();
//App.use(Router)

const routes = [

    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */'./views/Home.vue')

    },
    {
        path: '/cv',
        name: 'cv',
        component: () => import(/* webpackChunkName: "home" */'./views/CV.vue')

    },
    {
        path: '/filmography',
        name: 'filmography',
        component: () => import(/* webpackChunkName: "home" */'./views/Filmography.vue')

    },
    {
        path: '/devlog-floodplain',
        name: 'devlog floodplain',
        component: () => import(/* webpackChunkName: "home" */'./views/DevLogFloodPlain.vue')

    },
    {
        path: '/devlog',
        name: 'devlog',
        component: () => import(/* webpackChunkName: "home" */'./views/DevLogFloodPlain.vue')

    },
    {
        path: '/folio',
        name: 'folio',
        component: () => import(/* webpackChunkName: "home" */'./views/Folio.vue')

    },

    /*
    {
        path: "/thecommons",
        component: 'thecommons',
        beforeEnter(to, from, next) {
            window.open("https://www.ourcommoncode.org/fielddoc", '_blank');
      //      window.location.href = "https://www.ourcommoncode.org/fielddoc";
        }
    },
    {
        path: "/fdlogin",
        component: 'fdlogin',
        beforeEnter(to, from, next) {
            window.open("https://www.fielddoc.org/login", '_blank');
            //      window.location.href = "https://www.ourcommoncode.org/fielddoc";
        }
    },
    {
        path: "/fdsignup",
        component: 'fdsignup',
        beforeEnter(to, from, next) {
            window.open("https://www.fielddoc.org/register", '_blank');
            //      window.location.href = "https://www.ourcommoncode.org/fielddoc";
        }
    }

     */

    //https://www.fielddoc.org/login
]

//let pos = {left:0, top:0};


const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
 //   scrollBehavior(to, from, savedPosition) {
    scrollBehavior() {
         //   if(to.fullPath !== '/'){
        //        return { left: 0, top: 0 };
        //    }else{
        //        return { left: 0, top:  appState.homeScrollPos };

 //           }
            return { left: 0, top: 0 };

    },
})

export default router