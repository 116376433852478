import { createApp } from 'vue';
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
//import SvgIcon from '@jamescoyle/vue-icon'
//import { mdiAccount } from '@mdi/js'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import 'vuetify/styles';
import "vuetify/dist/vuetify.min.css";

import { createPinia } from "pinia";
import App from './App.vue'

import VueLazyLoad from 'vue3-lazyload';


//import { Quasar } from 'quasar' ;
//import "quasar/dist/quasar.sass";
//import quasarUserOptions from './quasar-user-options.js' ;




//import AppShell from './AppShell.vue'
import router from './router'

//import colors from 'vuetify/lib/util/colors'

//import 'vue-material/dist/vue-material.min.css'
//import 'vue-material/dist/theme/default.css'


const myCustomLightTheme = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        primary: '#6200EE',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00'
    }
}
const vuetify = createVuetify({
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {myCustomLightTheme}

    },
    options: {
        customProperties: true
    },
    icons: {
        iconfont: "mdi"
    },
    components,
    directives
} )


const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(vuetify);
//app.use(Quasar,quasarUserOptions);
app.use(VueLazyLoad, {
    loading: '',
    error: '',
    lifecycle: {
        loading: (el) => {
            console.log('loading', el)
        },
        error: (el) => {
            console.log('error', el)
        },
        loaded: (el) => {
            console.log('loaded', el)
        }
    }
})
app.mount('#app');